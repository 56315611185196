/* global */

#body.disablescroll {
  overflow: hidden;
}
#body.enablescroll {
  overflow: scroll;
}
.App {
  margin: 0;
  padding: 0;
  /* font-family: orbitron, sans-serif;
  font-weight: 400;
  font-style: normal; */
  /* font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 400; */
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  /* font-family: utopia-std-headline, serif;
  font-style: normal;
  font-weight: bold; */
  /* font-family: adobe-caslon-pro, serif;
  font-style: normal;
  font-weight: 600; */
  font-size: 14px;
  /* line-height: 1.34; */
}
body {
  line-height: 1.35;
  overflow: scroll;
}
.container {
  width: 70%;
  margin: auto;
}
ul {
  margin: 0;
  padding: 0;
}

li {
  display: inline;
}

/* Banner */

#banner {
  height: 66px;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  width: 100%;
}
#banner .bannerleft {
  flex: 1;
  color: #906c1c;
  display: flex;
  align-items: center;
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
}
#banner .bannerleft .bannerleftinner {
  margin-left: 15px;
}
#banner .bannerleft p {
  text-transform: uppercase;
  font-size: 14px;
}
#banner .bannercenter {
  flex: 1;

  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}
#banner .bannerright {
  flex: 1;
}
/* header */

header {
  min-height: 91px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 14;
  /* --box-shadow-color: palegoldenrod; */
  --box-shadow-color: #4a4a4a;
  box-shadow: 5px 0.5px 5px var(--box-shadow-color);
}
header .container {
  /* display: flex;
  flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

#logo {
  /* float: left; */
  color: black;
  /* width: 213px;
  height: 60px; */
  text-align: right;
  /* font-style: italic; */
  /* display: flex;
  align-items: center; */
  margin-top: 0px;
  float: left;
  position: relative;
}
#logo h1 {
  font-size: 26px;
  /* font-family: immi-five-o-five, sans-serif; */
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  /* font-weight: 600; */
  /* font-style: normal; */
  color: #333333;
  line-height: 0px;
  transition: 1s;
}
#logo p {
  font-size: 14px;
  margin: 0;
  line-height: 0.6;
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  color: black;
  transform: translateX(50px);
  transition: 1s;
}
#companylogotruck {
  width: 40px;
  transform: translateY(-34px) translateX(0px);
  position: absolute;
  transition: ease-in-out 1s;
}
/* .bar {
  display: none;
} */
nav {
  float: right;
  text-transform: capitalize;
  /* display: flex; */
  /* background-color: green; */
}
nav ul li .hr {
  display: none;
}
nav li a {
  color: #333333;
  text-decoration: none;
  padding: 9px 18px 9px 18px;
  font-size: 14px;
  font-weight: 600;
}
nav li a:hover {
  /* border-bottom: 1px black solid; */
  transition-duration: 0.3s;
  /* background-color: rgba(0, 0, 0, 0.36); */
  border: #906c1c 1px solid;
  color: rgba(0, 0, 0, 0.66);
}
nav li {
  margin-left: 4px;
  margin-right: 4px;
  /* display: flex; */
  /* align-items: center;
  justify-content: center; */
}
nav ul {
  float: left;
  margin-top: 11px;
  display: flex;
}
nav #contact-tab {
  float: right;
  width: 155px;
  height: 37px;
  border: #906c1c 1px solid;
  margin-left: 42px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav #contact-tab:hover {
  border: #906c1c 1.5px solid;
  transition-duration: 0.3s;
}

/* slideshow */

#slideshow {
  height: 80vh; /* 749px */
  width: 100%;
  background-color: black;
  position: relative;
  overflow: hidden;
}
#slideshowImage {
  width: 100%;
  height: 100%;
  color: white;
  background-image: url("../src/assets/images/white-volvo-semi-truck-on-side-of-road-2199293.jpg");
  background-size: cover;
  animation: animatebackImg;
  animation-duration: 20s;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  animation-iteration-count: infinite;
  animation-play-state: running;
}
@keyframes animatebackImg {
  0% {
    background-image: url("../src/assets/images/white-volvo-semi-truck-on-side-of-road-2199293.jpg");
  }
  25% {
    background-image: url("../src/assets/images/white-volvo-semi-truck-on-side-of-road-2199293.jpg");
  }
  50% {
    background-image: url("../src/assets/images/slide01.jpg");
  }
  75% {
    background-image: url("../src/assets/images/parked-trucks-under-clouds-2348359.jpg");
  }
  100% {
    background-image: url("../src/assets/images/slide01.jpg");
  }
}
#slideshow .slideshowoverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.36);
  display: flex;
  flex-direction: row;
}
#slideshow .slideshowoverlay #slideshowoverlayleft {
  flex: 0.7;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding-left: 15vw;
}
#slideshow .slideshowoverlay #slideshowoverlayleft #trails-text-wrapper {
  width: 588px;
}
#slideshow .slideshowoverlay #slideshowoverlayleft .trails-text {
  color: white;
  font-size: 24px;
  font-weight: 700;
  /* height: 270px; */
  width: 100%;
  will-change: transform, opacity;
}
#slideshow .slideshowoverlay #slideshowoverlayleft div p {
  color: white;
  font-size: 20px;
  font-weight: 500;
}
#slideshow .slideshowoverlay #slideshowoverlayleft #slideshowbuttons {
  display: flex;
  margin-top: 20px;
}
#slideshowbuttons .slideshowbutton {
  border: 1px solid white;
  height: 60px;
  padding: 0px 30px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
#slideshowbuttons .slideshowbutton:nth-child(1) {
  background-color: #906c1c;
}
#slideshowbuttons .slideshowbutton:nth-child(2) {
  margin-left: 20px;
}

#slideshow .slideshowoverlay #slideshowoverlayright {
  flex: 1;
}

/* Home */
#asideone {
  width: 100%;
  height: 320px;
  background-color: transparent;
  margin-top: 109px;
  transition-duration: 1.5s;
}
#asideone.show {
  width: 100%;
  height: 716px;
  margin-top: 109px;
  background-color: #f7f7f7;
  transition-duration: 2s;
}
#asideone #asideoneinside {
  width: 100%;
  height: 130px;
  background-color: transparent;
  position: absolute;
  left: 0;
  z-index: 1;
  overflow: hidden;
  transition-duration: 2s;
}
#asideone #asideoneinside.show {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  left: 0;
  z-index: 1;
  overflow: hidden;
}

#asideone #asideoneinside #aboutheader {
  color: #343232;
  font-size: 60px;
  margin: 63px 0 0 0;
}
#asideone #asideoneinside #aboutcontent {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  color: #4d4a4a;
  width: 632px;
  margin: 49.5px 0 0 0;
  opacity: 1;
  transition-duration: 1.5s;
}
#asideone #asideoneinside #aboutcontent.show {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  color: #4d4a4a;
  width: 632px;
  margin: 49.5px 0 0 0;
  opacity: 1;
  transition-duration: 1.5s;
}
#asideone #asideoneinside #aboutlearnmore {
  font-size: 14px;
  margin: 70.5px 0 256px 0;
  color: #343232;
  /* display: none; */
}
#asideone #asideoneinside #aboutlearnmore.show {
  font-size: 14px;
  margin: 70.5px 0 256px 0;
  color: #343232;
}

#asideone #asideoneoutside {
  width: 70%;
  height: inherit;
  background-color: #edefee;
  right: 0;
  position: absolute;
  transition: 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 1s;
}
#asideone #asideoneoutside.fullwidth {
  width: 100%;
  height: inherit;
  background-color: #edefee;
  right: 0;
  position: absolute;
  transition: 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 1s;
}
#asideone #asideoneoutside.fade {
  width: 100%;
  height: inherit;
  background-color: #edefee;
  right: 0;
  position: absolute;
  transition: 0.7s;
  display: flex;
  align-items: center;
  transition-duration: 0.3s;
  justify-content: center;
  opacity: 0;
  transition-duration: 1s;
}

#asidetwo {
  width: 100%;
  height: 418px;
  /* float: right; */
  margin-top: 92px;
  display: none;
  /* position: relative; */
}
#sectionservices {
  width: 100%;
  min-height: 1346px;
  margin-top: 92px;
  /* padding-bottom: 256px; */
  /* position: relative; */
}
#sectionservices #sectionservicesinside {
  width: 96%;
  background-color: transparent;
}
#asideone #asideoneinside #aboutheader,
#sectionservices #sectionservicesinside #servicesheader {
  color: #343232;
  font-size: 60px;
  margin: 63px 0 0 0;
}
#sectionservices #sectionservicesinside #servicescontent {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  color: #4d4a4a;
  width: 632px;
  margin: 49.5px 0 0 0;
}

#sectionservices #sectionservicesbar1 {
  width: 15%;
  min-height: 320px;
  margin-top: 92px;
  background-color: #edefee;
  transition-duration: 0.7s;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
}
#sectionservices #sectionservicesbar1.expand {
  width: 75%;
  min-height: 320px;
  margin-top: 92px;
  transition-duration: 0.7s;
  float: right;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(247, 247, 247, 0.6);
  overflow: visible;
}
#sectionservices #sectionservicesbar2 {
  width: 15%;
  min-height: 320px;
  margin-top: 92px;
  background-color: #edefee;

  transition: 0.7s;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-bottom: ; */
}
#sectionservices #sectionservicesbar2.expand {
  width: 75%;
  min-height: 320px;
  margin-top: 92px;
  background-color: rgba(247, 247, 247, 0.6);
  transition: 0.7s;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-bottom: ; */
}
.servicetypebox {
  width: 436px;
  height: 371px;
  background-color: white;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transition-duration: 1.5;
}
.servicetypebox.show {
  width: 436px;
  height: 371px;
  background-color: white;
  display: flex;
  flex-direction: column;
  opacity: 1;
}
.servicetypebox .servicetypeboxheader {
  font-size: 30px;
  color: #4a4a4a;
}
.servicetypebox .servicetypeboxcontent {
  font-size: 22px;
  color: #4d4a4a;
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 200;
}
#servicetypebox1 {
  margin-left: 74px;
  opacity: 0;
  transition-duration: 1s;
  transition-delay: 0.8s;
}
#servicetypebox1.show {
  margin-left: 74px;
  opacity: 1;
}
#servicetypebox2 {
  margin-left: 81px;
  opacity: 0;
  transition-duration: 1s;
  transition-delay: 0.8s;
}
#servicetypebox2.show {
  margin-left: 74px;
  opacity: 1;
}
#servicetypebox3 {
  margin-right: 61px;
  opacity: 0;
  transition-duration: 1s;
  transition-delay: 0.8s;
}
#servicetypebox3.show {
  margin-left: 74px;
  opacity: 1;
}
#servicetypebox4 {
  margin-right: 70px;
  opacity: 0;
  transition-duration: 1s;
  transition-delay: 0.8s;
}
#servicetypebox4.show {
  margin-left: 74px;
  opacity: 1;
}
#sectionworkwith {
  width: 100%;
  min-height: 518px;
  margin-top: 92px;
  padding: 33px 0;
  background-color: #f7f7f7;
}
#sectionworkwith #sectionworkwithheader,
#messagesectionheader {
  color: #343232;
  font-size: 50px;
  margin: 71px 0 0 0;
}
#sectionworkwith #sectionworkwithcontent {
  font-family: roboto, sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  color: #4d4a4a;
  width: 880px;
  /* margin: 49.5px 0 0 0; */
}
#companylogos {
  width: 85%;
  margin: auto;
  text-align: center;
}

#companylogos img {
  width: 100px;
  margin: 0 50px 50px 50px;
}

/* #sectionworkwith #sectionworkwithlogos {
} */

#asidetwo #asidetwoinside {
  width: 96%;
  height: inherit;
  background-color: transparent;
  position: absolute;
  right: 0;
}
#asidetwo #asidetwooutside {
  width: 96%;
  height: inherit;
  background-color: #edefee;
  position: absolute;
  left: 0;
  transition: 0.7s;
  display: flex;
  align-items: center;
  justify-content: center;
}
#asidetwo #asidetwoinside:hover {
  opacity: 0;
}
#messageform #messageformheader {
  font-size: 30px;
  color: #4a4a4a;
}
#locate {
  width: 100%;
  font-size: 30px;
  color: black;
  /* padding-left: 15%; */
  margin-top: 0px;
  margin-bottom: 45px;
  margin-top: 71px;
}
#locate p {
  color: #343232;
  font-size: 50px;
}
.inininin {
  border-radius: 0;
}
#map {
  width: 100%;
  height: 591px;
  margin-top: 0px;
  overflow: hidden;
}

#mapmessagecontainer {
  position: relative;
}

#mapinfowindow {
  width: 280px;
  height: 230px;
  display: flex;
  flex-direction: column;
  transition-duration: 0.5s;
  font-weight: 500;
  justify-content: space-evenly;
}
#mapinfowindow:hover {
  width: 300px;
  height: 250px;
}
#messagesection {
  /* width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end; */
  width: fit-content;
  position: absolute;
  top: 7rem;
  right: 15vw;
  z-index: 1;
  /* margin-right: 8.1%; */
  /* margin-top: -209px; */
  transition-duration: 0.5s;
}

#messageform {
  width: 450px;
  /* height: 394px; */
  background-color: transparent;
  border: 0px lightgray solid;
  color: #4a4a4a;
  font-size: 30px;
  padding-top: 49px;
  padding-left: 0px;
  text-align: left;
  transition-duration: 0.5s;
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  align-items: flex-start;
  flex-wrap: wrap;
}
#callsocial {
  display: none;
}
#messagedialogclose {
  float: right;
  align-self: flex-end;
  margin-right: 0px;
  position: absolute;
  right: 0;
  display: none;
}
.App-form-input-field {
  display: block;
  height: 40px;
  width: 45%;
  border: 0.5px solid lightgray;
  background-color: #f7f7f7;
  margin: 0 0.5% 22px 0.5%;
  padding-left: 3%;
  color: #4a4a4a;
}
.App-form-input-field:nth-child(5) {
  width: 100%;
  height: 150px;
}
.App-form-input-field:focus {
  outline: 2px #906c1c solid;
  border: 0px;
}
.input-error-text {
  font-size: 10px;
  font-family: acumin-pro, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: darkred;
  margin-top: -10px;
}
#submit {
  /* // borderWidth: 1, */
  /* // fontSize: 16, */
  padding: 10px 50px;
  height: 40px;
  margin: auto;

  /* float: left; */
  margin-right: 0px;
  /* // width: 428, */
  /* // // borderColor: colors.primary, */
  color: white;
  font-weight: 600;
  background-color: #906c1c;
  border: #906c1c 1px solid;
  /* width: 430px; */
}

/* footer */

.App-footer {
  width: 100%;
  min-height: 100px;
  background-color: #edefee;
  border: 0px solid #707070;
  color: #4a4a4a;
  font-size: 10px;
  float: left;
  font-weight: bold;
}
.App-footer-left {
  width: 50%;
  float: left;
}

.App-footer-left p {
  margin: 42px 0 0 3.7%;
  width: 100%;
  line-height: 20px;
}
.App-footer-right {
  width: 50%;
  float: right;
}
.App-footer-right div {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  margin-right: 4.3%;
  align-items: flex-end;
}
.App-footer-right div ul li {
  margin: 0px 10px;
}

/* About */
#intro1 {
  margin-top: 15%;
}
#intro1 p {
  width: 49%;
  height: 96px;
  font-size: 18px;
  color: #686868;
}
#aboutcontainer #img1 {
  width: 49%;
  margin-top: 16px;
  float: right;
}
#img1 img {
  float: right;
  object-fit: contain;
}
#intro2 {
  margin-top: 84px;
  width: 100%;
  float: left;
  font-size: 18px;
  height: 1000px;
}
#img2 {
  transform: translateX(60px);
  width: 100%;
}
#img2back {
  width: 100%;
  height: 85%;
  position: absolute;
  margin-top: 12%;
  background-color: #906c1c;
  z-index: -1;
}
#intro2 aside:nth-child(1) {
  width: 50%;
  float: left;
  position: relative;
  height: 100%;
}
#intro2 aside:nth-child(2) {
  float: right;
  width: 50%;
  /* font-family: kepler-std-semicondensed-dis, serif;
  font-weight: 700;
  font-style: normal; */
  color: #686868;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  /* padding: 50px 0; */
}
#intro2 aside:nth-child(2) p:nth-child(1) {
  /* width: 60%; */
  /* height: 60px; */
  margin-left: 24%;
  margin-top: 81px;
}
#intro2 aside:nth-child(2) p:nth-child(2) {
  /* width: 60%; */
  /* height: 60px; */
  margin-left: 24%;
  /* margin-top: 632px; */
  margin-bottom: 81px;
}
#heading2 {
  width: 617px;
  float: left;
  margin-top: 10%;
  font-size: 50px;
  color: #4a4a4a;
}
#img3 {
  width: 100%;
  float: left;
}
#img3 img {
  float: right;
}
#team {
  width: 100%;
  float: left;
  margin-top: 10%;
  margin-bottom: 179px;
  text-align: center;
  font-size: 20px;
  color: #4a4a4a;
}
#teamrow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.emloyeebox {
  border: 0.5px solid #000000;
  margin: 1%;
}

/*  News */

#newspage {
  font-family: roboto, sans-serif;
  font-weight: 300;
  font-style: normal;
  margin-top: 11%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 243px;
}
#newspage .container {
  width: 75%;
  display: flex;
  flex-direction: row;
}
#newsmainarticle {
  flex: 2;
  /* min-height: 974px; */
}
#newsmain {
  width: 100%;
  min-height: 974px;
  background-color: #f7f7f7;
  /* border: 1px solid #707070; */
}
#newsmain img {
  width: 100%;
  height: 520.5px;
  transition-duration: 0.5s;
}
#newsmain #newscontent {
  margin-left: 53.3px;
  margin-top: 61px;
  margin-right: 18.5%;
}
#newsmain #newscontent p {
  /* width: 684px; */
  color: #4d4a4a;
  font-size: 1.0625em;
  margin-bottom: 14px;
}
#newsmain #newscontent em {
  color: #343232;
  font-size: 30px;
}
#date {
  margin: 0 0 66px 0;
}
#newslist {
  flex: 1;
  height: 1112px;
  /* border: 0.5px solid #707070; */
  overflow: scroll;
}
#newsbox {
  width: 80%;
  height: 262px;
  margin: auto;
  margin-top: 60px;
  text-align: center;
}
#newsbox:nth-child(1) {
  background-color: #f7f7f7;
}
#newsbox img {
  width: 100%;
  height: 177px;
}

#share {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  font-size: 22px;
  align-items: center;
  margin-top: 32px;
}
#share img {
  width: 179px;
  height: 44px;
  margin-left: 21px;
}

/* Contact Page */
#contactpageheader {
  text-align: left;
  width: 100%;
  color: #343232;
  font-size: 50px;
  margin-top: 10vh;
}

#contactpagesection {
  min-height: 120vh;
}
#contactpagesection .container {
  display: flex;
  width: 75%;
}
#contactleft {
  flex: 1.8;
  /* display: flex; */
  /* flex-wrap: wrap; */
}
#contactleftbottom {
  display: flex;
  flex-wrap: wrap;
  flex: 1.2;
  line-height: 1.4;
}
.contactleftbottomboxes {
  flex-basis: 30%;
  margin-top: 60px;
}
h4 {
  color: #4a4a4a;
}
.contactleftbottomboxes ul {
  margin-top: 30px;
}
.contactleftbottomboxes ul li {
  margin-right: 15px;
}
.contactleftbottomboxes p {
  margin-top: 30px;
  font-size: 14px;
  color: #4a4a4a;
}
.contactleftbottomboxes:nth-child(2) p,
.contactleftbottomboxes:nth-child(4) p {
  color: #906c1c;
  font-weight: 600;
  cursor: pointer;
}
.contactleftbottomboxes:nth-child(4) p {
  text-decoration: underline;
}
.contactleftbottomboxes p a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
}
#contactleft #messageform {
  width: 85%;
}
#contactright {
  padding: 91px 0;
  flex: 1;
  height: 120vh;
}
.App-form-input-field {
  border-width: 0;
}
.App-form-input-field {
  border-width: 0;
}
#contactleft #messageform .App-form-input-field:focus {
  outline-width: 0.5px;
}
@media (max-width: 1168px) {
  #sectionservices #sectionservicesbar1.expand {
    width: 90%;
    min-height: 320px;
    margin-top: 92px;
    background-color: rgba(247, 247, 247, 0.6);
    transition: 0.7s;
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: visible;
  }
  #sectionservices #sectionservicesbar2.expand {
    width: 90%;
    min-height: 320px;
    margin-top: 92px;
    background-color: rgba(247, 247, 247, 0.6);
    transition: 0.7s;
    float: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* margin-bottom: ; */
  }

  #servicetypebox1.show {
    margin-left: 0;
    opacity: 1;
  }
  #servicetypebox2.show {
    margin-left: 0;
    opacity: 1;
  }
  #servicetypebox3 {
    margin-right: 0px;
    opacity: 0;
    transition-duration: 1s;
  }
  #servicetypebox3.show {
    margin-left: 0px;
    opacity: 1;
  }
  #servicetypebox4 {
    margin-right: 0px;
    opacity: 0;
    transition-duration: 1s;
  }
  #servicetypebox4.show {
    margin-left: 0px;
    opacity: 1;
  }
  #sectionworkwith #sectionworkwithcontent {
    width: 680px;
  }

  /* Contact Page */

  #contactpageheader {
    font-size: 40px;
  }

  #contactpagesection {
    min-height: 200vh;
  }
  #contactpagesection .container {
    flex-direction: column;
    width: 90%;
  }
  .contactleftbottomboxes {
    flex-basis: 50%;
  }
  #contactleft #messageform {
    width: 100%;
  }
  #contactright {
    height: 60vh;
  }
}
@media (max-width: 928px) {
  .container {
    width: 75%;
  }

  #messagesection {
    width: 100%;
    position: initial;
    top: 0;
    right: 0;
    z-index: 1;
    transition-duration: 0.5s;
    margin-bottom: 70px;
  }
  #messageform {
    width: 100%;
    margin: auto;
  }
  .App-form-input-field:focus {
    outline: 1px #906c1c solid;
    border: 0px;
  }
  /* News */
  #newspage .container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  /* global */

  .App {
    font-size: 14px;
  }
  #intro1,
  #intro1 p,
  #aboutcontainer #img1,
  #aboutcontainer #img2,
  #intro2 aside:nth-child(1),
  #intro2 aside:nth-child(2),
  #img3 img,
  #locate,
  #heading2,
  #newsmainarticle,
  #newslist,
  #newsmain,
  #slideshow .slideshowoverlay #slideshowoverlayleft,
  #asideone #asideoneinside #aboutcontent,
  #sectionservices #sectionservicesinside #servicescontent,
  #sectionworkwith #sectionworkwithcontent {
    float: none;
    width: 100%;
    text-align: left;
  }
  .container {
    width: 90%;
    margin: auto;
  }
  /* #logo,
  header nav,
  header nav ul,
  header nav #contact-tab,
  .emloyeebox {
    float: none;
    width: 100%;
    text-align: center;
  } */

  /* Header */

  header {
    min-height: 71px;
  }
  #logo h1 {
    font-size: 21px;
    font-weight: 600;
  }
  #logo p {
    font-size: 13px;
    line-height: 0.6;
    transform: translateX(50px);
  }
  #companylogotruck {
    width: 40px;
    transform: translateY(-30px) translateX(0px);
  }

  #logo {
    width: 100%;
    float: none;
    display: flex;
    justify-content: space-between;
    /* position: absolute; */
  }
  #bar {
    z-index: 15;
    margin-top: 10px;
  }
  #bar .line {
    border: 1px solid black;
    width: 18px;
    margin-bottom: 3px;
    transition-duration: 0.3s;
  }
  #logo #bar .line:nth-child(1) {
    margin-top: 3px;
  }
  nav #bar {
    align-self: flex-end;
    margin: 10%;
  }
  #bar .line.animate1 {
    transform: translateY(5px) rotate(45deg);
    transition: 0.3s;
    width: 16px;
  }
  #bar .line.animate2 {
    opacity: 0;
    transition: 0.3s;
  }
  #bar .line.animate3 {
    transform: translateY(-5px) rotate(-45deg);
    transition: 0.3s;
    width: 16px;
  }

  nav {
    background-color: white;
    border: 0.5px solid black;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 60vw;
    display: flex;
    flex-direction: column;
    display: none;
    z-index: 14;
  }
  nav.show {
    background-color: white;
    border: 0.5px solid black;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    width: 60vw;
    display: flex;
    flex-direction: column;
    display: block;
  }

  header nav ul,
  header nav #contact-tab {
    border-width: 0;
    float: none;
    width: 100%;
    text-align: left;
    /* margin-left: 0px; */
    /* margin-top: 0px; */
  }
  nav #contact-tab {
    display: block;
  }
  header ul {
    margin-top: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
  }
  nav ul li {
    margin-left: 0px;
    margin-right: 0px;
    display: block;
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 1px solid lightgray;
    width: 90%;
    margin: auto;
  }
  nav li a:hover {
    transition-duration: 0.3s;
    border: #906c1c 0px solid;
  }
  nav #contact-tab:hover {
    transition-duration: 0.3s;
    border: #906c1c 0px solid;
  }
  nav ul li a,
  nav #contact-tab {
    margin-left: 6%;
    font-weight: 600;
  }
  nav #contact-tab {
    margin-left: 10%;
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 5px solid lightgray;
  }
  nav li a {
    padding: 0 0 0 0;
  }
  #teamrow {
    display: grid;
    grid-template-columns: 1fr;
  }
  .emloyeebox {
    display: block;
  }

  /* About */

  #intro1 {
    margin-top: 5%;
  }

  #intro1 h3 {
    font-size: 30px;
    color: #4a4a4a;
    /* font-weight: 400; */
  }
  #intro1 p {
    margin-left: 0;
    text-align: left;
    margin-top: 0px;
    font-size: 18px;
    font-family: acumin-pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #686868;
    /* color: #4a4a4a; */
    line-height: 1.5;
    height: auto;
    margin-bottom: 0px;
  }
  #aboutcontainer #img1 {
    margin-top: 25px;
    margin-bottom: 0px;
  }
  #asideone {
    width: 275px;
    height: 320px;
  }
  #asidetwo {
    width: 100%;
    height: 50px;
    margin: 41px 0;
    display: inherit;
  }
  #sectioncontact {
    width: 311px;
    height: 418px;
  }

  #asideone #asideoneoutside,
  #asidetwo #asidetwooutside {
    font-size: 22px;
    text-align: center;
  }
  #intro2 {
    margin-top: 25px;
    height: auto;
  }
  #intro2 #img2back {
    margin-top: 0;
  }

  #intro2 #intro2left #img2 {
    transform: translateX(0px);
    height: auto;
    margin-top: 0px;
  }
  #intro2 aside:nth-child(2) {
    display: initial;
    height: auto;
  }

  #intro2 aside:nth-child(2) p:nth-child(1),
  #intro2 aside:nth-child(2) p:nth-child(2) {
    margin-left: 0;
    text-align: left;
    margin-top: 25px;
    font-size: 18px;
    font-family: acumin-pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #686868;
    /* color: #4a4a4a; */
    line-height: 1.5;
    margin-bottom: 25px;
  }

  #heading2 {
    font-size: 22px;
    height: fit-content;
    margin-top: 0px;
  }
  #heading2 h1 {
    height: auto;
    margin: 0px;
  }
  #img3 {
    margin-top: 25px;
  }
  #team {
    margin-top: 25px;
    margin-bottom: 75px;
  }
  .emloyeebox {
    width: 90%;
    margin: auto;
    margin-top: 25px;
  }
  .emloyeebox p {
    color: #686868;
    font-weight: bold;
    font-size: 16px;
  }
  /* Home */

  #slideshow {
    width: 100%;
    background-color: black;
    position: relative;
    height: 78vh;
  }
  #slideshow .slideshowoverlay {
    /* background-color: rgba(0, 0, 0, 0.6);  */
    background-color: rgba(0, 0, 0, 0.56);
    align-items: center;
  }
  header .container {
    width: 90%;
  }
  #slideshow .slideshowoverlay #slideshowoverlayleft {
    flex: 1;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    justify-content: flex-end;
    height: 90%;
  }
  #slideshow .slideshowoverlay #slideshowoverlayleft #trails-text-wrapper {
    width: auto;
  }
  #slideshowbuttons {
    width: 100%;
    flex-direction: column;
  }
  #slideshowbuttons .slideshowbutton {
    height: 55px;
    width: 100%;
    margin: auto;
    padding: 0px 0px 0px 0px;
  }
  #slideshowbuttons .slideshowbutton:nth-child(2) {
    margin-left: 0;
    margin: auto;
    margin-top: 15px;
    background-color: rgba(255, 255, 255, 0.04);
  }
  #slideshow .slideshowoverlay #slideshowoverlayright {
    flex: 0;
  }
  #slideshow .slideshowoverlay #slideshowoverlayleft div p {
    font-weight: 400;
    font-size: 20px;
  }
  #slideshow .slideshowoverlay #slideshowoverlayleft .trails-text {
    font-size: 20px;
  }
  #slideshow .slideshowoverlay #slideshowoverlayleft .trails-text h1 {
    line-height: 1.27;
  }
  #slideshow .slideshowoverlay #slideshowoverlayleft .trails-text h1,
  #slideshow .slideshowoverlay #slideshowoverlayleft .trails-text p {
    margin-top: 15px;
    margin-bottom: 5px;
  }

  /* .trails-text {
    position: relative;
    width: 100%;
    height: 180px;
    color: white;
    font-size: 129px;
    font-weight: 300;
    text-transform: capitalize;
    will-change: transform, opacity;
    overflow: hidden;
    padding-left: 30vw;
  } */

  #asideone.show {
    width: 100%;
    height: 480px;
    margin-top: 109px;
    background-color: #f7f7f7;
    transition-duration: 0.7s;
  }
  #asideone #asideoneoutside {
    transition-duration: 0.3s;
  }
  #asideone #asideoneoutside.fullwidth {
    transition-duration: 0.3s;
  }
  #asideone #asideoneoutside.fade {
    transition-duration: 0.7s;
  }
  #asideone #asideoneoutside.fullwidth {
    transition-duration: 1s;
  }
  #asideone #asideoneoutside.fade {
    transition-duration: 0.7s;
  }
  /* #asideone #asideoneinside {
    transition-duration: 1s;
  } */

  #sectionservices {
    width: 100%;
    min-height: 1090px;
    margin-top: 92px;
    margin-bottom: 0px;
    /* position: relative; */
  }

  #asideone #asideoneinside #aboutheader,
  #sectionservices #sectionservicesinside #servicesheader,
  #sectionworkwith #sectionworkwithheader,
  #messagesectionheader,
  #locate p {
    font-size: 40px;
    color: #4a4a4a;
  }

  #asideone #asideoneinside #aboutcontent,
  #sectionservices #sectionservicesinside #servicescontent,
  #sectionworkwith #sectionworkwithcontent,
  .servicetypebox .servicetypeboxcontent {
    margin-top: 15px;
    font-size: 18px;
    font-family: acumin-pro, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #686868;
    /* color: #4a4a4a; */
    line-height: 1.5;
  }
  #asideone #asideoneinside #aboutlearnmore {
    font-size: 14px;
    margin: 70.5px 0 256px 0;
    /* color: #686868; */
    text-decoration: underline;
    color: #4a4a4a;
    /* display: none; */
  }

  #sectionservices #sectionservicesbar1.expand {
    width: 100%;
    min-height: 320px;
    margin-top: 92px;
    background-color: white;
    transition: 0.7s;
    float: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    overflow: visible;
  }
  #sectionservices #sectionservicesbar2.expand {
    width: 100%;
    min-height: 320px;
    margin-top: 0px;
    background-color: white;
    transition: 0.7s;
    float: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }

  .servicetypebox {
    width: 80%;
    height: 371px;
    background-color: white;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition-duration: 1.5s;
  }
  .servicetypebox.show {
    width: 80%;
    height: 371px;
    background-color: rgba(247, 247, 247, 0.6);
    display: flex;
    flex-direction: column;
    opacity: 1;
    padding-left: 5%;
    padding-right: 5%;
  }
  .servicetypebox .servicetypeboxheader {
    font-size: 30px;
    color: #4a4a4a;
    line-height: 0px;
  }
  #servicetypebox1 {
    margin-left: 0px;
    opacity: 0;
    transition-duration: 1s;
  }
  #servicetypebox1.show {
    margin-left: 0px;
    opacity: 1;
  }
  #servicetypebox2 {
    margin-left: 0px;

    opacity: 0;
    transition-duration: 1s;
  }
  #servicetypebox2.show {
    margin-left: 0px;
    opacity: 1;
  }
  #servicetypebox3 {
    margin-right: 0px;
    opacity: 0;
    transition-duration: 1s;
  }
  #servicetypebox3.show {
    margin-left: 0px;
    opacity: 1;
  }
  #servicetypebox4 {
    margin-right: 0px;
    opacity: 0;
    transition-duration: 1s;
  }
  #servicetypebox4.show {
    margin-left: 0px;
    opacity: 1;
  }

  #sectionworkwith {
    padding: 63px 0 13px 0;
    min-height: max-content;
  }
  #sectionworkwith #sectionworkwithheader {
    margin: 0 0 0 0;
  }

  #companylogos {
    width: 90%;
    margin-top: 30px;
    float: none;
    margin: 50px auto;
    text-align: center;
  }

  #companylogos img {
    margin-right: 25px;
    margin-left: 25px;
    width: 50px;
  }

  #locate {
    width: "100%";
    font-size: 30px;
    /* padding-left: 15%; */
    margin-top: 60px;
    margin-bottom: 0px;
  }
  .App-form-input-field {
    width: 100%;
    border-radius: 0;
  }
  #submit {
    height: 50px;
    width: 100%;
    font-size: 14px;
  }

  #map {
    width: 100%;
    height: 66vh;
    margin-top: 0px;
    overflow: hidden;
    top: 0;
  }
  #visitusheader {
    font-size: 30px;
    color: #4a4a4a;
  }
  #newspage {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #newspage .container {
    width: 95%;
    flex-direction: column;
  }
  #newsmain {
    background-color: white;
  }
  #newsmain #newscontent {
    margin-left: 0;
    margin-top: 61px;
    margin-right: 0;
    /* background-color: white; */
  }
  #newsmain img {
    width: 100%;
    height: 50vh;
  }
  #date {
    margin: 8px 0 66px 0;
  }
  #newslist {
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-right-width: 0px;
  }
  #share {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    font-size: 22px;
    align-items: center;
    margin-top: 0px;
  }

  /* News */

  /* footer */

  .App-footer {
    width: 100%;
    min-height: 120px;
    background-color: #edefee;
    border: 0px solid #707070;
    color: #4a4a4a;
    font-size: 12px;
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #686868;
    font-weight: bold;
  }
  .App-footer-left {
    width: 100%;
    float: none;
    display: none;
  }
  /* .App-footer p {
    line-height: 25px;
  } */

  .App-footer-left p {
    margin: 30px 0 0 0;
    width: auto;
    line-height: inherit;
  }
  .App-footer-right {
    width: 100%;
    float: none;
  }
  .App-footer-right div p {
    margin-top: 15px;
  }
  .App-footer-right div {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    margin-right: 0;
    align-items: center;
  }
  .App-footer-right div ul li {
    margin: 0px 10px;
  }
}

@media (orientation: landscape) and (max-width: 768px) {
  #slideshow {
    height: 630px;
  }
}
